import React from 'react'
import { Link, graphql } from 'gatsby'

import BaseLayout from '../templates/base'
import Head from '../components/head'
import SocialCard from '../components/social-card'
import PageHeader from '../components/page-header'

import config from '../../config/site-config'
import formatHtmlTitle from '../utils/formatHtmlTitle'

const BlogPage = ({ data }) => {
	const postsGroupedByYear = data.allMarkdownRemark.edges.reduce((acc, post) => {
		const { date } = post.node.frontmatter;
		(acc[date] = acc[date] || []).push(post);
		return acc;
	}, {});

	const years = Object.keys(postsGroupedByYear).sort((a, b) => new Date(b) - new Date(a));
	
	console.log(postsGroupedByYear);
	
	return (
		<BaseLayout>
			<Head title={formatHtmlTitle(`Blog`)} />
			<SocialCard title={formatHtmlTitle(`Blog`)} />
			<PageHeader title="Blog" />
			<div className="post-section-list">
				{years.map(year => (
					<section key={year} className="post-section">
						<h2 className="post-section-title">{year}</h2>
						<ol className="post-list">
							{postsGroupedByYear[year].map(post => {
								const id = post.node.id
								const title = post.node.frontmatter.title
								const description = post.node.frontmatter.description
								const excerpt = post.node.excerpt
								const link = config.blogPrefix + post.node.frontmatter.slug
								const linkTitle = "Read ”" + title + "”"

								return (
									<li key={id} className="post-list--item">
										<Link to={link} title={linkTitle} className="post-link">
											<h1 className="title">
												{title}
											</h1>

											{
												description && <h2 className="description">
													{description}
												</h2>
											}

											<p className="excerpt">{excerpt}</p>
										</Link>
									</li>
								)
							})}
						</ol>
					</section>
				))}
			</div>
		</BaseLayout>
	)
}

export default BlogPage

export const blogQuery = graphql`
	query {
		allMarkdownRemark(
			filter: { fileAbsolutePath: { regex: "/blog\/" } }
			sort: { fields: [frontmatter___date], order: DESC }
		) {
			edges {
			node {
				id
				excerpt(pruneLength: 150)
				frontmatter {
					title
					description
					slug
					date(formatString: "y")
				}
			}
			}
		}
	}
	`;
